import React, { useState } from 'react';

import { Link } from 'gatsby';
import Layout from '../components/layouts/layout';
import SEO from '../components/seo';
import stolenByClownsIllustration from '../images/page-stolen-by-bad-clowns.png';
import fohOhFohIllustration from '../images/foh-oh-foh.svg';

function NotFoundPage() {
  const [imageSource, toggleSource] = useState(fohOhFohIllustration);
  return (
    <Layout>
      <SEO title="404: Not found" robots="noindex" />
      <div>
        <span onClick={() => toggleSource(stolenByClownsIllustration)}>
          <img
            src={imageSource}
            className="block mx-auto w-1/2"
            alt="Ghost getting abducted by aliens"
          />
        </span>
        <h2 className="inline-block my-8 p-3">
          Evil clowns have stolen the page you were looking for.
          <br />
          <br />
          No, really! See for yourself by clicking on the image below.
          <br />
          <br />
          Convinced? Try going back to{' '}
          <Link className="text-blue-500" to="/">
            the homepage
          </Link>{' '}
          and give it another go.
        </h2>
      </div>
    </Layout>
  );
}

export default NotFoundPage;
